img {
    /* to avoid links adding extra space below images*/
    /* see https://stackoverflow.com/questions/5804256/image-inside-div-has-extra-space-below-the-image */
    vertical-align: middle;
}

.avatar.avatar--transparent {
    background-color: var(--cirrus-bg) !important;
}

.avatar-square.avatar--header {
    opacity: 0.95;
}

@supports (-webkit-touch-callout: none) {
    /* Hide element on iOS device */
    .u-none-ios {
        display: none !important;
    }
}

.poster {
    position: relative;
    line-height: 1;
    height: 7.2rem;
    width: 4.8rem;
}

/* To display list position as an overlay over a poster */
.poster .after {
    position: absolute;
    top: 0;
    left: 3px;
    width: 100%;
    height: 100%;
    color: white;
    font-weight: 800;
    text-shadow:
        -1px 0 black,
        0 1px black,
        1px 0 black,
        0 -1px black;
}

.avatar-square.avatar-square--small {
    width: 2.5rem;
}

.avatar-square {
    border-radius: 20%;
}

.vidchuck-input {
    border: 0 !important;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0 0 !important;
    color: transparent;
}

body {
    /* Trick to make the footer stick to the bottom for short pages */
    display: flex;
    flex-direction: column;
}

h1 {
    font-size: 1.75rem !important;
}

.wrapper {
    /* This trick is also needed to keep the footer at the bottom of short pages. */
    /* Additionally, it's needed to keep header from expanding for short pages. */
    flex-grow: 1;
}

.header-vidchuck {
    /* Make bottom margin adjust size based on viewport. */
    margin-bottom: 1vh !important;
    background-color: transparent;
    box-shadow: none;
    opacity: 0.9;
    padding: 0.2rem 0.5rem !important;
}

.header-vidchuck .nav-right {
    flex-grow: 1 !important;
    display: flex !important;
    justify-content: flex-end;
}

footer {
    padding: 0 5vh 1rem !important;
    background-color: var(--cirrus-bg) !important;
    margin-top: 0 !important;
}

footer a {
    display: inline !important;
    color: var(--cirrus-fg) !important;
}

.content-vidchuck {
    margin-bottom: auto !important;
}

.vidchuck-list {
    list-style: none inside none;
}

.vidchuck-list li::before {
    content: '🍿';
}

.vidchuck-list-info {
    list-style: none inside none;
}

.vidchuck-list-info li {
    margin: 0 0 1rem 0;
}

.toast {
    margin: 0 !important;
}

.modal-content .vidchuck-modal-header {
    max-height: 20% !important;
}

.modal-content .vidchuck-modal-body {
    padding: 0 1.5rem !important;
}

.vidchuck-modal-footer-sticky {
    position: sticky;
    background: linear-gradient(0deg, rgb(234 237 240) 40%, transparent 100%);
    bottom: 0;
    display: inline-flex;
    width: 100%;
}

.vidchuck-modal-footer,
.vidchuck-modal-header {
    padding: 1vh 1.5vh !important;
}

.vidchuck-tile__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-top: 0.5rem;
    margin-left: 0.5rem;
}

/* MOBILE */
@media screen and (max-width: 767px) {
    .vidchuck-u-none-xs {
        display: none !important;
    }

    .header-vidchuck {
        flex-direction: row !important;
    }

    .header-vidchuck .header-nav {
        height: inherit !important;
        width: 100% !important;
    }

    /* On mobile make it actually bottom-right*/
    .tooltip.tooltip--bottom-left::after {
        left: auto !important;
        transform: translate(15%, 0) !important;
    }

    .tooltip.tooltip--bottom-left:hover::after,
    .tooltip.tooltip--bottom-left:focus::after {
        right: 0 !important;
        transform: translate(100%, -0.5rem) !important;
    }

    footer .add-top-padding {
        padding-top: 0.9rem !important;
    }

    h1 {
        font-size: 1.5rem !important;
    }

    .content {
        width: 100% !important;
    }

    .frame {
        margin-left: 1rem !important;
        margin-right: 1rem !important;
    }

    /* For the frame header and footer, use flex display */
    .frame .frame__header-vidchuck,
    .frame .frame__footer-vidchuck {
        padding: 0.1rem !important;
    }

    .modal {
        padding: 0 !important;
    }

    .modal-content {
        max-width: none !important;
    }

    .vidchuck-no-border-radius-on-mobile {
        border-radius: 0 !important;
    }
}

@media screen and (min-width: 768px) {
    .content {
        width: 80% !important;
    }
}

.tag.tag--region {
    padding-left: 0 !important;
}

.input-control.input-control--region {
    margin: 0;
}

a.a-icon {
    color: var(--cirrus-dark);
}

a.a-icon:hover {
    color: var(--cirrus-dark-hover);
}

.vidchuck-select {
    height: 100% !important;
}

.noHover {
    pointer-events: none;
}

.table td {
    vertical-align: middle !important;
}

.vsb-main .title {
    overflow-wrap: anywhere !important;
}

#availableServices ul {
    list-style: none;
}

#availableServices li {
    vertical-align: middle;
    display: inline-block;
}

#availableServicesFirst li {
    margin-right: 1rem !important;
}

.normal-line-height {
    line-height: 1rem !important;
}

.collapsible {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    text-align: left;
}

.collapsible.active {
    background-color: #ccc;
}

.vidchuck-no-pointer {
    cursor: default !important;
}

.vidchuck-no-margin-bottom {
    margin-bottom: 0 !important;
}

.vidchuck-no-margin-right {
    margin-right: 0 !important;
}

iframe {
    border: none !important;
    box-shadow: none !important;
    background-color: var(--cirrus-bg) !important;
}

.vidchuck-no-padding {
    padding: 0 !important;
}

.toast.toast--light {
    background-color: var(--cirrus-light);
    border-color: var(--cirrus-light);
    color: var(--cirrus-fg);
}

.toast.toast--light a {
    color: var(--cirrus-link) !important;
}

.attribution {
    font-size: 0.85rem;
    color: #979797;
}

.vidchuck-search-bar-wrapper {
    width: 90%;
    max-width: 600px;
    margin-bottom: 2rem;
}

.vidchuck-btn-dynamic-size-2 {
    padding: 0.3rem 1rem !important;
}

.vidchuck-iframe-container {
    min-height: 86px;
    display: grid;
}

.iframe-wrapper,
.loader {
    grid-area: 1 / 1;
}

.loader {
    height: 47px;
    width: 47px;
    color: rgba(90, 90, 90, 0.2);
    position: relative;
    display: inline-block;
    border: 5px solid;
    border-radius: 50%;
    border-right-color: #5a5a5a;
    animation: rotate 1s linear infinite;
    z-index: 1;
    margin: 0.6em 1em;
}

.iframe-wrapper {
    z-index: 2;
}

@keyframes rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(360deg);
    }
}

.vidchuck-href {
    text-decoration: underline !important;
    color: var(--cirrus-dark) !important;
    padding: 1px !important;
}

.hidden-horizontal-scrollbar {
    overflow: hidden;
    display: flex;
    position: relative;
}

.hidden-horizontal-scrollbar__items {
    overflow-x: auto;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    position: relative;
    flex-wrap: nowrap;
    display: flex;
    align-items: center;
}

.hidden-horizontal-scrollbar__items > * {
    flex: 0 0 auto;
}

.discovery-recs__hero {
    color: #fff;
    position: relative;
}

.discovery-recs__hero__header__right {
    max-width: 50%;
}

.discovery-recs__hero__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.discovery-recs__hero__providers .filter-bar__provider-icon {
    width: 32px;
    height: fit-content;
}

.filter-bar__provider-icon {
    display: inline-block;
    width: 50px;
    height: 50px;
    opacity: 0.25;
    margin: 10px 5px;
    transition: opacity 0.2s ease-in-out;
}

.filter-bar__provider-icon.active {
    opacity: 1;
}

.filter-bar__provider-icon--plus {
    height: fit-content;
    display: inline-block;
    transition: opacity 0.2s ease-in-out;
}

.provider-icon,
.provider-icon {
    border-radius: 20%;
}

.provider-icon,
.provider-icon img {
    border-radius: 20%;
}

#service-selector input[type='checkbox'] {
    display: none;
}

#service-selector label {
    width: 100%;
}

.input-control > label {
    display: flex !important;
}

#service-selector div.service-checkbox-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 7px;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: var(--cirrus-fg);
    background-color: var(--cirrus-light-gray);
}

#service-selector div.service-checkbox-wrapper:hover {
    background-color: var(--cirrus-light-hover);
}

#service-selector input[type='checkbox']:checked + div.service-checkbox-wrapper {
    background-color: var(--cirrus-gray);
}

#service-selector input[type='checkbox']:checked + div.service-checkbox-wrapper > i.icon-checked {
    display: block;
}

#service-selector input[type='checkbox']:checked + div.service-checkbox-wrapper > i.icon-unchecked {
    display: none;
}

#service-selector input[type='checkbox'] + div.service-checkbox-wrapper > i.icon-checked {
    display: none;
}

#service-selector input[type='checkbox'] + div.service-checkbox-wrapper > i.icon-unchecked {
    display: block;
}

.wrapped-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
}

/* CSS class like the one above but show the shadow on the left and right instead
of top and bottom*/
.scroll-shadows-horizontal {
    background: /* Shadow covers */
        linear-gradient(to right, white 30%, rgba(255, 255, 255, 0)),
        linear-gradient(to right, rgba(255, 255, 255, 0), white 70%) 100% 0,
        /* Shadows */ radial-gradient(farthest-side at 0 50%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 100% 50%, rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0)) 100% 0;
    background-repeat: no-repeat;
    background-color: white;
    background-size:
        40px 100%,
        40px 100%,
        14px 100%,
        20px 100%;

    /* Opera doesn't support this in the shorthand */
    background-attachment: local, local, scroll, scroll;
}

.image-container {
    position: relative;
    display: inline-block;
}
.badge {
    position: absolute;
    top: -8px;
    right: 8px;
    background-color: rgba(240, 82, 82);
    color: white;
    border-radius: 40%;
    min-width: 20px;
    height: 20px;
    font-size: 0.75rem;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    z-index: 1;
}

.vidchuck-form-group-label {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.vidchuck-text-xs {
    font-size: 0.75rem !important;
    line-height: 1.5rem !important;
}

.clock-loading {
    display: inline-block;
    overflow: hidden;
    height: 1.3em;
    margin-top: -0.3em;
    line-height: 1.5em;
    vertical-align: text-bottom;
}

.clock-loading::after {
    display: inline-table;
    white-space: pre;
    content: '🕐\A🕑\A🕒\A🕓\A🕔\A🕕\A🕖\A🕗\A🕘\A🕙\A🕚\A🕛';
    animation: spin12 3s steps(12) infinite;
    width: 1.5em;
}

.info-data-point {
    vertical-align: middle;
}

@keyframes spin12 {
    to {
        transform: translateY(-18em);
    }
}
